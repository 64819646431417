<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="students"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field === 'image'">
          <template>
            <img :src="props.row.image?imgShow(props.row.image):'/student_face.gif'" width="50" height="50" alt="">
          </template>
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          {{status[props.row.is_active]}}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button variant="outline-primary" @click="viewDetails(props.row)">
                  <feather-icon class="text-body " icon="EyeIcon" size="15"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- view model -->
    <b-modal ref="information-view" hide-footer size="xl" :title="$t('Information View')">
      <b-row>
        <b-col lg="4">
          <b-card-group deck>
            <b-card>
              <div class="text-center">
                <b-avatar :src="student.image?imgShow(student.image):'/student_face.gif'" size="6rem"></b-avatar>
                <h1 class="mb-0">{{ (student.userable) ? student.userable.name : '' }}</h1>
                <h6 class="my-2">Student</h6>
              </div>
              <table>
                <tr>
                  <td class="font-weight-bold">Student Id</td>
                  <td>: {{ student.student_id }}</td>
                </tr>
                <tr>
                  <td>Wing</td>
                  <td>: </td>
                </tr>
              </table>
            </b-card>
          </b-card-group>
        </b-col>
        <b-col lg="8">
          <b-card>
            <b-tabs pills card>
              <b-tab title="Profile" active>
                <b-card-body>
                  <b-row>
                    <b-col md="5">
                      <table>
                        <tr v-if="student.roll">
                          <td>Roll</td>
                          <td>: {{ student.roll }}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>: {{ (student.userable) ? student.userable.email : '' }}</td>
                        </tr>
                        <tr>
                          <td>Phone number</td>
                          <td>: {{ (student.userable) ? student.userable.phone_number : '' }}</td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>: {{ student.userable ? genderById(student.userable.gender_id) : '' }}</td>
                        </tr>
                        <tr>
                          <td>Blood group</td>
                          <td>:{{ bloodGroupById((student.userable) ? student.userable.blood_group_id : '') }}</td>
                        </tr>
                        <tr>
                          <td>Religion</td>
                          <td>: {{ religionById((student.userable) ? student.userable.religion_id : '') }}</td>
                        </tr>
                        <tr>
                          <td>Version</td>
                          <td>: {{ student.version_id }}</td>
                        </tr>
                        <tr>
                          <td>Shift</td>
                          <td>: {{ student.shift_id }}</td>
                        </tr>
                      </table>

                    </b-col>
                    <b-col md="2"></b-col>
                    <b-col md="5">
                      <table>
                        <tr>
                          <td>Class</td>
                          <td>: {{ student.classes_id }}</td>
                        </tr>
                        <tr>
                          <td>Student Group</td>
                          <td>: {{ student.student_group_id }}</td>
                        </tr>
                        <tr>
                          <td>Section</td>
                          <td>: {{ student.section_id }}</td>
                        </tr>
                        <tr>
                          <td>Academic year</td>
                          <td>: {{ student.academic_year_id }}</td>
                        </tr>
                        <tr>
                          <td>Special promotion</td>
                          <td :style="student.is_special_promotion?'color:green':'color:red'">:
                            {{ (student.is_special_promotion === 1) ? 'yes' : 'no' }}
                          </td>
                        </tr>
                        <tr>
                          <td>Date of birth</td>
                          <td>: {{ student.date_of_birth }}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td :style="student.is_active?'color:green':'color:red'">:
                            {{ (student.is_active === 1) ? 'active' : 'inactive' }}
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Parent">
                <b-card-body>
                  <b-row>
                    <b-col md="5">
                      <table>
                        <tr>
                          <td>Father Name</td>
                          <td>: {{ (student.parent) ? student.parent.father_name : '' }}</td>
                        </tr>
                        <tr>
                          <td>Father Profession</td>
                          <td>: {{ (student.parent) ? student.parent.father_profession : '' }}</td>
                        </tr>
                        <tr>
                          <td style="width:120px">Father Phone Number</td>
                          <td>: {{ (student.parent) ? student.parent.father_phone_number : '' }}</td>
                        </tr>
                        <tr>
                          <td>Father NID</td>
                          <td>: {{ (student.parent) ? student.parent.father_nid_no : '' }}</td>
                        </tr>
                        <tr v-if="student.parent ? student.parent.father_email: '' ">
                          <td>Father Email</td>
                          <td>: {{ (student.parent) ? student.parent.father_email : '' }}</td>
                        </tr>
                        <tr>
                          <td>Mother Name</td>
                          <td>: {{ (student.parent) ? student.parent.mother_name : '' }}</td>
                        </tr>
                        <tr>
                          <td>Mother Profession</td>
                          <td>: {{ (student.parent) ? student.parent.mother_profession : '' }}</td>
                        </tr>
                        <tr>
                          <td style="width:120px">Mother Phone Number</td>
                          <td>: {{ (student.parent) ? student.parent.mother_phone_number : '' }}</td>
                        </tr>
                        <tr>
                          <td>Mother NID</td>
                          <td>: {{ (student.parent) ? student.parent.mother_nid_no : '' }}</td>
                        </tr>
                      </table>
                    </b-col>
                    <b-col md="2"></b-col>
                    <b-col md="5">
                      <table>
                        <tr v-if="student.parent ? student.parent.mother_email: '' ">
                          <td>Mother Email</td>
                          <td>: {{ (student.parent) ? student.parent.mother_email : '' }}</td>
                        </tr>
                        <tr>
                          <td>Present Address</td>
                          <td style="min-width:120px">: {{
                              (student.parent) ? student.parent.present_address : ''
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Permanent Address</td>
                          <td>: {{ (student.parent) ? student.parent.permanent_address : '' }}</td>
                        </tr>
                        <tr>
                          <td>Local Guardian Name</td>
                          <td>: {{ (student.parent) ? student.parent.local_guardian_name : '' }}</td>
                        </tr>
                        <tr>
                          <td>Local Guardian Phone</td>
                          <td>: {{ (student.parent) ? student.parent.local_guardian_phone_number : '' }}</td>
                        </tr>
                        <tr>
                          <td>Relation</td>
                          <td>: {{ (student.parent) ? student.parent.relation : '' }}</td>
                        </tr>
                        <tr>
                          <td>Local Guardian Address</td>
                          <td>: {{ (student.parent) ? student.parent.local_guardian_address : '' }}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td :style="student.parent?student.parent.is_active?'color:green':'color:red':'color:red'">:
                            {{ student.parent ? student.parent.is_active ? 'active' : 'Inactive' : 'Inactive' }}
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <!--            {{ students }}-->
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,
  BRow, BCol, BAvatar, BCardGroup, BCard, BCardBody, BTabs, BTab
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";

export default {
  name: 'Student',
  components: {
    BCardCode,
    VueGoodTable, BRow, BCol, BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BCardGroup, BCard, BCardBody, BTabs, BTab
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      selected_row: {},
      student: {},
      columns: [
        {
          label: this.$t('SL'),
          field: 'id',
        },
        {
          label: this.$t('Photo'),
          field: 'image',
        },
        {
          label: this.$t('Student Number'),
          field: 'cadet_no',
        },
        {
          label: this.$t('Name'),
          field: 'userable.name',
        },
        {
          label: this.$t('Class'),
          field: 'classes.name',
        },
        {
          label: this.$t('Student Group'),
          field: 'student_group.name',
        },
        {
          label: this.$t('Academic Year'),
          field: 'academic_year.year',
        },
        {
          label: this.$t('Status'),
          field: 'is_active',
        },
        /*{
          label: this.$t('Action'),
          field: 'action',
        },*/
      ],
      searchTerm: '',
      status: {
        1: this.$t('active'),
        0: this.$t('deactivate'),
      },
      students:[],
    }
  },
  methods: {
    viewDetails(row) {
      this.student = row;
      this.$refs['information-view'].show()
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
    async getInfo(){
      await apiCall.get('/parent/all/child').then((response)=>{
        this.students=response.data;
      }).catch(()=>{
        this.students=[];
      })
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-success',
        0: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters([
      'religions', 'religionById',
      'genders', 'genderById', 'blood_groups', 'bloodGroupById']),
  },
  created() {
    this.getInfo();
    if (this.religions.length < 1) this.$store.dispatch('GET_ALL_RELIGION');
    if (this.blood_groups.length < 1) this.$store.dispatch('GET_ALL_BLOOD_GROUP');
    if (this.genders.length < 1) this.$store.dispatch('GET_ALL_GENDER');
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
